export default defineNuxtRouteMiddleware((to, _) => {
  if (to.path.includes('/f-clinic') && !to.path.endsWith('/f-clinic')) {
    return navigateTo(
      {
        path: to.path.replace('/f-clinic', ''),
        query: to.query,
      },
      {
        redirectCode: 301,
      }
    )
  }
})
